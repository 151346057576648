import * as $ from "jquery";
// TODO: TW Can we get rid of this?  import jsCookie = require("js-cookie");
import * as userMessaging from "./svc-user-messaging";
import { TokenRepository } from '../TokenRepository';

export function GetDataRequestService(): IDataRequestService {
    return new DataRequestService();
}

export interface IRequestSent {
    (): void;
}

export interface IDataRequestService {
    SendJsonRequest(type: string, url: string, success, error): void;
    SendJsonRequestWithData(type: string, url: string, data, success, error, async): void;
    SendJsonRequestWithDataVerbose(type: string, url: string, data, success, error, withCredentials, async: boolean): void;
    refreshAccessToken(tokens: any): Promise<any>;
    sendJsonRequestForFile(url, data, fileExt, success, error): void;
    //TODO: TW can we remove this?
    //ReadCookie(name: string): Object;
    //ReadCookieContent(name: string): string;
}

export var OnRequestSent: IRequestSent;

export function SetOnRequestSent(requestSent: IRequestSent): void {
    OnRequestSent = requestSent;
}

class DataRequestService implements IDataRequestService {
    private isRefreshingToken = false;
    private tokenRepository: TokenRepository;

    constructor() {
        this.tokenRepository = new TokenRepository();
    }

    private showMessage(message: string, messageType: userMessaging.MessageTypeOption): void {
        const service = userMessaging.GetUserMessagingService();
        service.SendMessage(new userMessaging.UserMessage(message, messageType));
    }

    public SendJsonRequest(type, url, success, error): void {
        this.SendJsonRequestWithData(type, url, null, success, error, false);
    }

    public SendJsonRequestWithData(
        type,
        url,
        data,
        success,
        error,
        async
    ): void {
        this.SendJsonRequestWithDataVerbose(type, url, data, success, error, false, async);
    }

    public SendJsonRequestWithDataVerbose(
        type,
        url,
        data,
        success,
        error,
        withCredentials,
        async
    ): void {
 
        // Convert data to JSON if it's not null
        const dataToSend = data ? type.toUpperCase() === 'GET' ? data : JSON.stringify(data) : null;

        // Make the AJAX request with the updated token
        this.makeAjaxRequest(type, url, dataToSend, success, error, async, null, withCredentials);
    }  

    public sendJsonRequestForFile(url, data, fileExt, success, error) {

        this.makeAjaxRequest('POST', url, JSON.stringify(data), success, error, true, fileExt);
    }

    private makeAjaxRequest(type, url, dataToSend, success, error, async, fileExt, withCredentials = false) {

        const waitForTokenRefresh = async () => {
            console.log('Moved request into the queue: ' + url);
            const startTime = Date.now();
            while (this.isRefreshingToken) {
                if (Date.now() - startTime >= 1500) {
                    this.showMessage("An error occured trying to extend your session, please close your browser and try again.", userMessaging.MessageTypeOption.Error);
                    return null;
                }
                await new Promise(resolve => setTimeout(resolve, 100));
            }
        };

        const sendRequest = () => {
            const tokens = this.tokenRepository.getToken();
            console.log('sending ' + url);            
            $.ajax({
                method: type,
                url: process.env.REACT_APP_API_URL.concat(url),
                headers: {
                    Authorization: 'Bearer ' + (tokens === null ? '' : tokens.access_Token)
                },
                xhrFields: setResponseTypeForXhr(withCredentials),
                async: async,
                data: dataToSend,
                contentType: 'application/json',
                success: success,
                error: (jqXHR, reason) => {
                    let hasError: boolean = false;
                    // handle 401 response 
                    if (jqXHR.status === 401) {
                        const isTokenExpired = jqXHR.getResponseHeader('Token-Expired') === 'true';
                        if (tokens === null) {
                            if (jqXHR.responseText) {
                                this.showMessage(jqXHR.responseText, userMessaging.MessageTypeOption.Error);
                                hasError = true;
                            } else {
                                window.location.reload(); // no tokens when calling a protected resource - reload will show login screen
                            }
                        }
                        else if (isTokenExpired) {
                            if (this.isRefreshingToken) {
                                console.log('need to requeue ' + url);
                                this.makeAjaxRequest(type, url, dataToSend, success, error, async, fileExt);
                            } else {
                                this.refreshAccessTokenAndRetry(type, url, tokens, dataToSend, success, error, async, fileExt);
                            }
                        }
                        else {
                            // this is a legit permissions issue
                            if (jqXHR.responseText) {
                                this.showMessage(jqXHR.responseText, userMessaging.MessageTypeOption.Error);
                            } else {
                                this.showMessage("An error occurred trying to request information.", userMessaging.MessageTypeOption.Error);
                            }
                            hasError = true;
                        }
                    } else if (jqXHR.status === 403) {

                        if (jqXHR.responseText) {
                            this.showMessage(jqXHR.responseText, userMessaging.MessageTypeOption.Error);
                        } else {
                            this.showMessage("You do not have permission", userMessaging.MessageTypeOption.Error);
                        }
                        if (error) error(jqXHR);
                    } else if (jqXHR.status === 400) { 
                        if (jqXHR.responseJSON) {
                            const msg = this.formatValidationErrors(jqXHR.responseJSON);
                            if (msg)
                                this.showMessage(msg, userMessaging.MessageTypeOption.Error);
                            else
                                this.showMessage('Bad request', userMessaging.MessageTypeOption.Error);
                        } else {
                            this.showMessage(jqXHR.responseText, userMessaging.MessageTypeOption.Error);
                        }                        
                    } else if (jqXHR.status === 500) {
                        this.showMessage('There was a problem with your request. If you continue to see this message, please contact the Help Desk.', userMessaging.MessageTypeOption.Error);
                        if (jqXHR.responseText) {
                            console.log(jqXHR.responseText);
                        }
                    } else {
                        // handle other errors
                        const allHeaders = jqXHR.getAllResponseHeaders();
                        const headersMap = {};
                        try {
                            // Parse headers
                            const headersArray = allHeaders.trim().split(/[\r\n]+/);
                            headersArray.forEach(function(line) {
                                const parts = line.split(': ');
                                const header = parts.shift();
                                const value = parts.join(': ');
                                headersMap[header.toLowerCase()] = value; // Normalize header names to lowercase
                            });
                        } catch (error) {
                            console.error('Error parsing headers:', error);                           
                        }

                        // Check for the custom error message header
                        const errorMessage = headersMap['x-error-message'];
                        if (errorMessage) {
                            this.showMessage(errorMessage, userMessaging.MessageTypeOption.Error);
                        }
                        else if (jqXHR.responseJSON && jqXHR.responseJSON.message) {
                            this.showMessage(jqXHR.responseJSON.message, userMessaging.MessageTypeOption.Error);
                        } else {
                            this.showMessage("An error occurred trying to request information: " + reason, userMessaging.MessageTypeOption.Error);
                        }
                        hasError = true;
                    }
                    if (hasError && error) {
                        error(jqXHR);
                    }
                }
            });

            function setResponseTypeForXhr(withCredentials): JQuery.Ajax.XHRFields {                
                // If fileExt is empty, return empty object
                if(fileExt === null || fileExt === '') {
                    return { withCredentials };
                }
                console.log(fileExt.toLowerCase());
                // For HTML files, responseType should be 'text'
                if(fileExt.toLowerCase() === '.html') {
                    return {
                        responseType: 'text',
                        withCredentials
                    };
                }
                
                // For all other files, responseType should be 'blob'
                return {
                    responseType: 'blob',
                    withCredentials
                };
            }
        }

        if (this.isRefreshingToken) {
            waitForTokenRefresh().then(() => sendRequest());
        } else {
            sendRequest();
        }
    }

    // Refresh token
    private refreshAccessTokenAndRetry(
        type,
        url,
        tokens,
        dataToSend,
        success,
        error,
        async,
        fileExt
    ) {       
        // try to refresh the token
        this.refreshAccessToken(tokens)
            .then(response => {
                // refresh was successful, store new tokens and re-make the request
                this.tokenRepository.saveToken(response);
                this.makeAjaxRequest(type, url, dataToSend, success, error, async, fileExt);
            })
            .catch(refreshError => {
                // refresh failed
                console.log('Token refresh failed:', refreshError);
                sessionStorage.setItem('loginMessage', 'You have been logged out due to inactivity.');
                this.tokenRepository.saveToken(null);
                window.location.reload(); // reload will show login screen
            })
            .finally(() => {

            });
    }

    public refreshAccessToken(tokens: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {

            if (!this.isRefreshingToken) {
                this.isRefreshingToken = true;

                fetch(process.env.REACT_APP_API_URL.concat('/api/login/Refresh'), {
                    method: 'POST',
                    body: JSON.stringify({
                        Access_Token: tokens.access_Token,
                        HoldTokenKey: '',
                        Refresh_Token: tokens.refresh_Token,
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        reject('Unable to refresh access token');
                    }
                })
                .then(data => {
                    resolve(data); // Resolve the promise to indicate success
                })
                    .catch(err => {
                    // this indicates an error contacting the API server
                    this.showMessage("An error occured trying to extend your session, please close your browser and try again.", userMessaging.MessageTypeOption.Error);
                    reject(err); // Reject the promise to indicate failure
                }).finally(() => {
                    this.isRefreshingToken = false;
                });
            } else {
                console.log('refresh in progress - do nothing.')
            }
        });
    }

    private formatValidationErrors(response: any): string {
        if (!response.errors || typeof response.errors !== "object") return "";

        // Extract the errors and format them
        const errorMessages = Object.entries(response.errors).map(
            ([key, value]) => `${key}: ${Array.isArray(value) ? value.join(", ") : value}`
        );

        // Join all the error messages with commas
        return errorMessages.join(", ");
    }

    //public sendJsonRequestForFile(data, url, nameOfFile) {
    //    const tokens = this.tokenRepository.getToken();
    //    const xhr = new XMLHttpRequest();
    //    xhr.open('POST', process.env.REACT_APP_API_URL.concat(url), true);
    //    xhr.setRequestHeader('Authorization', 'Bearer ' + (tokens === null ? '' : tokens.access_Token));
    //    xhr.responseType = 'arraybuffer';
    //    xhr.setRequestHeader('Content-Type', 'application/json');

    //    xhr.onload = function () {
    //        if (xhr.status === 200) {
    //            const data = xhr.response;
    //            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //            const link = document.createElement('a');
    //            link.href = window.URL.createObjectURL(blob);
    //            link.download = nameOfFile;
    //            document.body.appendChild(link);
    //            link.click();
    //            document.body.removeChild(link);
    //        } else if (xhr.status === 401) {

    //        } else {
    //            console.error('AJAX error:', xhr.statusText);
    //        }
    //    };

    //    xhr.onerror = function () {
    //        console.error('A network error occurred');
    //    };

    //    xhr.send(JSON.stringify(data));
    //}
}

    // TODO: TW Can we get rid of this?
    //public ReadCookie(name): Object {
    //    var cookieText = jsCookie.get(name);
    //    if (cookieText) {
    //        if (cookieText.indexOf('=') > -1) {
    //            var cookieObject = {};
    //            var splitCookie = cookieText.split('&');
    //            splitCookie.forEach(function (value, index) {
    //                var setting = value.split('=');
    //                cookieObject[setting[0]] = setting[1];
    //            });
    //            return cookieObject;
    //        } else { return null }
    //    }
    //}

    //public ReadCookieContent(name): string {
    //    return jsCookie.get(name);
    //}
//}